/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "LCD"; //This is what we are going to call
  src: url("./assets/Digit.TTF");
}

@font-face {
  font-family: "LCDText"; //This is what we are going to call
  src: url("./assets/Seven Segment.ttf");
}

ion-content {
  --background: var(--ion-color-light);
}
// .dark-theme {
//   ion-content {
//     --background: var(--ion-color-dark);
//   }
// }

ion-label {
  ion-icon {
    margin-bottom: -3px;
  }
}

ion-title {
  ion-icon {
    margin-bottom: -3px;
  }
}

ion-input.border {
  border: 1px solid var(--ion-color-medium);
  --padding-start: 3px !important;
  margin: 5px;
  border-radius: 5px;
}

// .buttonbadge {
//   position:relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
.flex-column-small {
  display: flex;
  flex-direction: column;
}
.flex-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.flex-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.block {
  display: block;
}

.flex-wrap {
  flex-wrap: wrap;
}
#periodChoice {
  width: 94%;
  margin: auto;
}
.green {
  color: var(--ion-color-success-shade);
}

.red {
  color: var(--ion-color-danger-shade);
}

.primary {
  color: var(--ion-color-primary-shade);
}
ion-popover {
  &::part(content) {
    min-width: 250px;
  }
}

ion-popover.w400 {
  &::part(content) {
    min-width: 340px;
  }
}
ion-select {
  &::part(label){
    margin-bottom:8px;
  }
}
ion-popover.w600 {
  &::part(content) {
    min-width: 500px;
  }
}
.margin-auto {
  margin: auto;
}

ion-card {
  border-radius: 5px;
  // margin: 16px 8px 10px 8px;
}

@media (min-width: 1000px) {
  ion-modal {
    --width: 700px;
    --min-width: 700px;
    --height: 800px;
    --max-height: 90%;;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  ion-alert {
    --width: 600px !important;
    --min-width: 600px !important;
    --height: 800px !important;
    --max-height: 90% !important;
    .alert-checkbox-group {max-height: 100% !important;}
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  }
}


ion-modal.remove-score-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}


@media print {
  ion-header,
  ion-backdrop,
  .tabbar,
  ion-footer,
  ion-content::part(background) {
    display: none !important;
  }
  ion-nav {
    contain: none;
    overflow: visible;
  }
  ion-split-pane {
    display: block;
  }
  .scroll-content,
  ion-modal,
  ion-modal .modal-wrapper,
  ion-modal .ion-page,
  ion-modal .ion-page > ion-content,
  .ion-page,
  .ion-page > ion-content,
  ion-split-pane,
  ion-tab,
  ion-tabs,
  ion-router-outlet,
  .app-root,
  ion-content::part(scroll),
  body {
    contain: none;
    position: initial;
    height: auto;
    overflow: visible;
  }
  .fixed-content,
  .scroll-content {
    margin-top: 0 !important;
  }

  hr {
    page-break-after: always;
  }

  table,
  table tr td,
  table tr th,
  ion-item {
    page-break-inside: avoid;
  }
}

ion-chip.match-result {
  margin-left: 0px;
}

.add-custom-match-select .alert-wrapper {
  max-width: 90% !important;
}

ion-split-pane {
  --side-width: 295px;
  --side-max-width: 295px;
}

.tutorial {
  .swiper-pagination-bullet {
    background: var(--ion-color-warning) !important;
  }
}

.small-padding {
  padding: 6px;
}

app-bracket {
  width: 100%;
  // min-width: 600px;
  max-width: 1000px;
  display: block;
}
pinch-zoom {
  max-width: 1000px;
  background-color: #ffffff !important;
}


.alertWarning {
  // --background: var(--ion-color-danger);
  // --color: var(--ion-color-dark);
}

ion-popover {
  --width: auto;
}
// .generic-popover-wide400 {
//   app-generic-popover {
//     width: 400px;
//   }
// }
ion-toast.custom-toast {
  --background: #8f0000;
  // --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  // --color: #d0d0d0;
}

// Add to your global styles
.feature-spotlight-overlay {
  position: fixed !important;
  z-index: 99999 !important;
}