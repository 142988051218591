// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


:root {

	--ion-color-primary: #0ea5dd;
	--ion-color-primary-rgb: 14,165,221;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0c91c2;
	--ion-color-primary-tint: #26aee0;

	// --ion-color-primary: #101010;
	// --ion-color-primary-rgb: 16,16,16;
	// --ion-color-primary-contrast: #9fde21;
	// --ion-color-primary-contrast-rgb: 159,222,33;
	// --ion-color-primary-shade: #000000;
	// --ion-color-primary-tint: #202020;

	--ion-color-secondary: #9fde21;
	--ion-color-secondary-rgb: 159,222,33;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #8cc31d;
	--ion-color-secondary-tint: #a9e137;

	--ion-color-tertiary: #FEEA1D;
	--ion-color-tertiary-rgb: 254,234,29;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #e0ce1a;
	--ion-color-tertiary-tint: #feec34;

	--ion-color-success: #22c362;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #da2840;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-toolbar-background: var(--ion-color-light);
}


:root {
	--ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}


ion-modal.auto-height::part(content) {
  position: relative;
  top: 55px;
  bottom: 0px;
  height: 87%;
  }


  ion-modal.wrestler-import-modal {
	--width: 90%;
	--height: 90%;

	@media (min-width: 1000px) {
	  --width: 950px;
	  --height: 800px;

	  &::part(content) {
		border-radius: 8px;
	  }
	}
  }

  .pre .alert-message {
	white-space: pre;
  }